// eslint-disable-next-line no-unused-vars
import React from 'react';
import ChevronImageUrl from '../assets/images/chevron-down.png';
import styled from 'styled-components';

const ChevronImage = styled.img`
  height: 16px;
  width: auto;
  color: transparent;
  border: 0px transparent solid;
  margin: 8px;

  @media (max-width: 805px) {
    height: 8px;
  }
`;

const Chevron = (props) => <ChevronImage {...props} src={ChevronImageUrl} />;

export default Chevron;

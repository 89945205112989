// eslint-disable-next-line no-unused-vars
import React from 'react';
import {getDownloadLink} from '../../helpers/';
import {Link, Trans} from 'gatsby-plugin-react-i18next';
import Logo from '../Logo';
import {ActionMenuItems, MainMenuItems, MenuItem, MenuItemsContainer, NavigationBarContainer} from '../styled';

function DesktopNavBar() {
  const downloadLink = getDownloadLink();

  return (
    <NavigationBarContainer>
      <Logo small />
      <MenuItemsContainer>
        <MainMenuItems>
          <MenuItem as={Link} to="#why">
            <Trans>Why CindyApp?</Trans>
          </MenuItem>
          {/* <MenuItem as={Link} to="#reviews">
          Reviews
        </MenuItem> */}
          <MenuItem as={Link} to="#industries">
            <Trans>Industries</Trans>
          </MenuItem>
          {/* <MenuItem to="/blog">Blog</MenuItem> */}
          {/* <MenuItem to="/press">Press</MenuItem> */}
          <MenuItem as={Link} to="#faq">
            <Trans>FAQ</Trans>
          </MenuItem>
          <MenuItem as={Link} to="/business">
            <Trans>Businesses</Trans>
          </MenuItem>
          {/* <MenuItem to="/careers">Careers</MenuItem> */}
        </MainMenuItems>
        <ActionMenuItems>
          <MenuItem as={Link} $border to="/business/sign-up">
            <Trans>Become a partner</Trans>
          </MenuItem>
          <MenuItem as="a" href={downloadLink} target="_blank" $reverse $border>
            <Trans>DOWNLOAD NOW</Trans>
          </MenuItem>
        </ActionMenuItems>
      </MenuItemsContainer>
    </NavigationBarContainer>
  );
}

export default DesktopNavBar;

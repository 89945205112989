/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import Accordion from './Accordion';
import SectionContainer from './SectionContainer';
import SectionHeader from './SectionHeader';
import SectionSubheader from './SectionSubheader';
import styled from 'styled-components';
import ContentContainer from './styled/ContentContainer';

const AccordionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  margin-top: 16px;
`;

function FAQ({id, data = []}) {
  return (
    <SectionContainer id={id}>
      <ContentContainer>
        <SectionHeader>
          <Trans>FAQ</Trans>
        </SectionHeader>
        <SectionSubheader>
          <Trans>Frequently asked questions</Trans>
        </SectionSubheader>
        <AccordionsContainer>
          {data.map((accordionData, index) => (
            <Accordion key={index} {...accordionData} />
          ))}
        </AccordionsContainer>
      </ContentContainer>
    </SectionContainer>
  );
}

export default FAQ;

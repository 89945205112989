// eslint-disable-next-line no-unused-vars
import React, {useContext} from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import SectionHeader from '../SectionHeader';
import SectionSubheader from '../SectionSubheader';
import SectionContainer from '../SectionContainer';
import styled from 'styled-components';
import ImageBefore from '../../assets/images/why-cindy-before.jpg';
import ImageAfter from '../../assets/images/why-cindy-after.jpg';
import RedSpan from '../RedSpan';
import {getDownloadLink} from '../../helpers';
import CTAButton from '../styled/CTAButton';
import ContentContainer from '../styled/ContentContainer';

const Image = styled.img`
  height: 480px;
  display: flex;
  flex: 1;
`;

const MobileImage = styled.img`
  width: 100%;
  max-width: 512px;
  height: auto;
  margin-top: 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: #392f5a;
  padding: 32px;
  border-radius: 8px;
  max-width: 600px;
`;

const ImageText = styled.div`
  font-size: 2.2rem;
  padding: 32px;
  text-align: center;
  color: white;

  @media (max-width: 1092px) {
    padding: 0px;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 32px;
`;

const DownloadNowButton = styled(CTAButton)`
  margin-top: 20px;
  width: 200px;
`;

function WhyUseIt(props) {
  const downloadLink = getDownloadLink();

  return (
    <SectionContainer id="why">
      <ContentContainer>
        <div>
          <SectionHeader>
            <Trans>Why we created it</Trans>
          </SectionHeader>
          <SectionSubheader>
            <Trans>and how you can use it</Trans>
          </SectionSubheader>
        </div>
        {/* <Text>
        "We created CindyApp because we got tired of using different platforms every time we want to
        book an appointment.
        We believe everything should be in ONE place and you should be able to book an appointment with 3 clicks of a
        button. No registrations, no website, no platforms - just ONE app."
      </Text> */}
        <Row style={{marginTop: 16}}>
          <Image src={ImageBefore} className="hide-on-mobile" />
          <TextContainer>
            <ImageText>
              {/* eslint-disable-next-line max-len */}
              <Trans i18nKey="Are you <1>TIRED</1> of having to use different websites, apps or having to call in just to make an appointment?">
                Are you <RedSpan>TIRED</RedSpan> of having to use different websites, apps or having to call in just to
                make an appointment?
              </Trans>
            </ImageText>
          </TextContainer>
        </Row>
        <MobileImage src={ImageBefore} className="hide-on-desktop" />
        {/* <Chevron style={{height: 32}} /> */}
        <Row style={{marginTop: 32}}>
          <TextContainer>
            <ImageText>
              <span style={{fontSize: '1.2em'}}>
                <Trans>We thought so too!</Trans>
              </span>
              <br />
              <br />
              {/* eslint-disable-next-line max-len */}
              <Trans i18nKey="That is why we created an app that allows you to book appointments at your favorite places in<1> 3 SIMPLE STEPS.</1>">
                That is why we created an app that allows you to book appointments at your favorite places in
                <RedSpan> 3 SIMPLE STEPS.</RedSpan>
              </Trans>
            </ImageText>
            <DownloadNowButton as="a" href={downloadLink} target="_blank" $reverse $border>
              <Trans>DOWNLOAD NOW</Trans>
            </DownloadNowButton>
          </TextContainer>
          <Image style={{marginTop: -120}} src={ImageAfter} className="hide-on-mobile" />
        </Row>
      </ContentContainer>
    </SectionContainer>
  );
}

export default WhyUseIt;

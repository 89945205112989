// eslint-disable-next-line no-unused-vars
import React, {useContext} from 'react';
import {Trans, Link} from 'gatsby-plugin-react-i18next';
import SectionContainer from '../SectionContainer';
import SectionHeader from '../SectionHeader';
import SectionSubheader from '../SectionSubheader';
import styled from 'styled-components';
import ProviderImage from '../../assets/images/man-compressed.png';
import {Row} from '../styled';
import ContentContainer from '../styled/ContentContainer';
import CTAButton from '../styled/CTAButton';

const Container = styled(SectionContainer)`
  padding-bottom: 0px;

  @media (max-width: 1092px) {
    padding-bottom: 50px;
  }
`;

const StyledContentContainer = styled(ContentContainer)`
  background-color: #8070fd;
  border-radius: 8px;
  padding: 32px 64px 0px;

  @media (max-width: 805px) {
    padding: 32px;
  }
`;

// const Image = styled.div`
//   display: flex;
//   flex-grow: 4;
//   flex: 1;
//   background-image: url(${ProviderImage});
//   background-position: center center;
//   background-size: cover;
//   background-repeat: no-repeat;
//   border-radius: 20px;
//   height: 450px;
// `;

const Header = styled.div`
  font-size: 2.6rem;
  font-weight: bold;
  color: white;
  margin-bottom: 8px;
  line-height: 1.3;

  text-align: left;

  @media (max-width: 1092px) {
    font-size: 2rem;
    width: 100%;
  }
`;

const Text = styled.div`
  font-size: 1.6rem;
  color: white;
  text-align: left;

  @media (max-width: 1092px) {
    width: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: normal;

  @media (max-width: 1092px) {
    align-items: center;
  }
`;

const Image = styled.img`
  width: 80%;
  object-fit: contain;
`;

const Content = styled(Row)`
  gap: 16px;
  margin-top: 64px;

  @media (max-width: 1092px) {
    margin-top: 24px;
  }
`;

const LearnMoreButton = styled(CTAButton)`
  width: 200px;
  margin-left: 0px;
  margin-top: 46px;
  margin-bottom: 24px;

  @media (max-width: 1092px) {
    margin: 24px 0px 0px 0px;
  }
`;

function BusinessSection(props) {
  return (
    <Container>
      <StyledContentContainer>
        <SectionHeader inverted>
          <Trans>Are you a business owner?</Trans>
        </SectionHeader>
        <SectionSubheader inverted>
          {/* <Button to="/business/" $border as={Link} style={{width: 200, marginLeft: 0, marginTop: 10}}>
          Let us help you.
        </Button> */}
        </SectionSubheader>
        <Content>
          <Column>
            <Header>
              <Trans>Make your clients happy</Trans>
            </Header>
            <Text>
              <Trans>Let them easily book appointments with our beautiful app</Trans>
            </Text>
            <Header style={{marginTop: 32}}>
              <Trans>Save time and money</Trans>
            </Header>
            <Text>
              <Trans>Eliminate the need for a dedicated staff member to take calls and handle schedules</Trans>
            </Text>
            <LearnMoreButton to="/business" $border as={Link}>
              <Trans>LEARN MORE</Trans>
            </LearnMoreButton>
          </Column>
          <Column style={{alignItems: 'center'}} className="hide-on-mobile">
            <Image src={ProviderImage} />
          </Column>
        </Content>
      </StyledContentContainer>
    </Container>
  );
}

export default BusinessSection;

// eslint-disable-next-line no-unused-vars
import React from 'react';
import './AnimatedText.css';

function AnimatedText(props) {
  return (
    <div className="animated-text" style={{color: props.color, fontSize: props.size}}>
      {props.children}
    </div>
  );
}

export default AnimatedText;

// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
// import {CSSTransitionGroup} from 'react-transition-group';

const Container = styled.div`
  width: 225px;
  height: 455px;
  overflow: hidden;
  position: relative;
`;

const Screenshot = styled.div`
  position: absolute;
  width: 202px;
  height: 433px;
  top: 10.5px;
  left: 11px;
  background-size: cover;
  background-image: url(${(props) => props.backgroundImage});
  z-index: 1;
`;

const DeviceImage = styled.img`
  position: absolute;
  width: 225px;
  z-index: 2;
`;

// TODO: Make this more generic
function Device({deviceImage, screenshots, counter}) {
  const screenshot = screenshots[counter];

  // TODO: CSS Transitions
  return (
    <Container>
      <DeviceImage alt="screenshot" src={deviceImage} />
      {/* <CSSTransitionGroup
        transitionClass="slide"
        transitionEnterTimeout={100}
        transitionLeaveTimeout={100}
        key={counter}
      > */}
      <Screenshot backgroundImage={screenshot} />
      {/* </CSSTransitionGroup> */}
    </Container>
  );
}

export default Device;

/* eslint-disable relay/graphql-syntax */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import BusinessSection from '../components/HomePage/BusinessSection';
import WhyUseIt from '../components/HomePage/WhyUseIt';
import IndustriesSection from '../components/IndustriesSection';
import TopFold from '../components/HomePage/TopFold';
import FAQ from '../components/FAQ';
// import {getDownloadLink} from '../helpers';
import Navbar from '../components/HomePage/NavBar';
import Footer from '../components/Footer';
import IndustryDialog from '../components/HomePage/IndustryDialog';

const FAQ_DATA = [
  {
    title: 'How can I add places and book appointments? I do not see any places on CindyApp.',
    text: 'Please ask your service vendor to give you their unique QR code or invite link. If your vendor does not have a CindyApp account, please have them contact us as sales@cindyapp.com and we will open one for them.',
  },
  {
    title: 'Is CindyApp free?',
    text: 'Yes, CindyApp is completely free. You can download it right now and start booking appointments!',
  },
  {
    title: 'I do not see the places I go to on CindyApp. I cannot use CindyApp to book appointments.',
    text: 'Maybe we have not integrated with your favorite places, yet. Please ask those business to contact us at sales@cindyapp.com and we will get them started in no time.',
  },
  {
    title: 'Can I cancel an appointment once it is confirmed?',
    text: 'Yes, of course! We allow our users to cancel and modify appointments. Please note that the service vendor might have also applied some restiction such as not allowing cancelations certain time before the start of your appointment.',
  },
  {
    title: 'I do not get a verification code when I try to log in.',
    text: 'Please send us an email stating your phone number at support@cindyapp.com',
  },
  {
    title: 'Other questions',
    text: 'Please contact us at support@cindyapp.com and we will be happy to help with whatever questions or issues you have!',
  },
];

// const PROMOTION_DATA = [
//   // {
//   //   text: 'Book your next appointment with few clicks.',
//   //   buttonText: 'Download app',
//   //   link: getDownloadLink(),
//   //   outsideLink: true,
//   // },
//   {text: 'Book appointments at your favorite places!', link: '/add-place'},
//   {text: 'Do you operate a business? Let us handle your schedule!', link: '/business'},
// ];

// markup
const IndexPage = () => {
  const [isIndustryModalOpen, setIndustryModalOpen] = useState(false);

  return (
    <main>
      <Helmet>
        <title>CindyApp - Book your next appointment</title>
        <meta name="theme-color" content="#8070fd" />
      </Helmet>
      <Navbar />
      {/* <PromotionBar data={PROMOTION_DATA} /> */}
      <TopFold />
      <IndustriesSection
        id={'industries'}
        header={'A one stop app'}
        subheader={'For all of your services'}
        onClick={() => setIndustryModalOpen(true)}
      />
      <WhyUseIt />
      <BusinessSection />
      <FAQ id="faq" data={FAQ_DATA} />
      <Footer />
      <IndustryDialog isOpen={isIndustryModalOpen} onClose={() => setIndustryModalOpen(false)} />
    </main>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

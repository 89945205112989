import styled from 'styled-components';

const SectionSubheader = styled.h4`
  color: ${(props) => (props.inverted ? 'white' : 'gray')};
  text-align: center;

  @media (min-width: 805px) {
    margin-bottom: 40px;
  }
`;

export default SectionSubheader;

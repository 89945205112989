// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import {Accordion as BSTRPAccordion} from 'react-bootstrap';
import styled from 'styled-components';
import Chevron from './Chevron';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  border: 1px solid #8070fd;
  overflow: hidden;
  margin-bottom: 20px;
`;

const Title = styled.div`
  display: flex;
  color: #8070fd;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 8px 12px;
  font-size: 1.6rem;
`;

const Text = styled.div`
  font-size: 1.6rem;
  padding: 16px 24px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: lightgray;
  border-radius: 2px;
`;

function Accordion({title, text}) {
  return (
    <BSTRPAccordion>
      <Container>
        <Title>
          <BSTRPAccordion.Toggle as={Title} variant="link" eventKey="0">
            <Trans>{title}</Trans>
            <Chevron />
          </BSTRPAccordion.Toggle>
        </Title>
        <Separator />
        <BSTRPAccordion.Collapse eventKey="0">
          <Text>
            <Trans>{text}</Trans>
          </Text>
        </BSTRPAccordion.Collapse>
      </Container>
    </BSTRPAccordion>
  );
}

export default Accordion;

/* eslint-disable relay/unused-fields */
/* eslint-disable relay/graphql-syntax */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';
import './NavBar.css';

const Navbar = () => {
  return (
    <>
      <div className="index-navbar-mobile">{<MobileNavBar />}</div>
      <div className="index-navbar-desktop">{<DesktopNavBar />}</div>
    </>
  );
};

export default Navbar;

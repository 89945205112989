// eslint-disable-next-line no-unused-vars
import React, {useCallback, useState} from 'react';
import {Drawer} from '@mui/material';
import {Trans} from 'gatsby-plugin-react-i18next';
import {getDownloadLink} from '../../helpers/';
import Logo from '../Logo';
import {
  ActionDrawerItems,
  Button,
  CloseButton,
  DrawerContainer,
  DrawerHeader,
  DrawerItem,
  MobileNavigationBarContainer,
} from '../styled';

function MobileNavBar() {
  const downloadLink = getDownloadLink();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = useCallback(() => setIsDrawerOpen(!isDrawerOpen), [setIsDrawerOpen, isDrawerOpen]);

  return (
    <MobileNavigationBarContainer>
      <Logo small />
      <Button $border onClick={toggleDrawer}>
        <Trans>Menu</Trans>
      </Button>
      <Drawer anchor={'right'} open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <DrawerContainer>
          <DrawerHeader>
            <Logo small navigateTo="/" />
            <CloseButton onClick={toggleDrawer} />
          </DrawerHeader>
          <DrawerItem onClick={toggleDrawer} to="#why" $borderBottom>
            <Trans>Why CindyApp?</Trans>
          </DrawerItem>
          {/* <DrawerItem to="#reviews">Reviews</DrawerItem> */}
          <DrawerItem onClick={toggleDrawer} to="#industries" $borderBottom>
            <Trans>Industries</Trans>
          </DrawerItem>
          {/* <DrawerItem to="/careers">Careers</DrawerItem> */}
          <DrawerItem onClick={toggleDrawer} to="#faq" $borderBottom>
            <Trans>FAQ</Trans>
          </DrawerItem>
          <DrawerItem to="/business" $borderBottom>
            <Trans>Businesses</Trans>
          </DrawerItem>
          <ActionDrawerItems>
            <DrawerItem style={{marginBottom: 15, textAlign: 'center'}} $border to="/business/sign-up">
              <Trans>Become a partner</Trans>
            </DrawerItem>
            <DrawerItem style={{textAlign: 'center'}} $reverse $border to={downloadLink}>
              <Trans>DOWNLOAD NOW</Trans>
            </DrawerItem>
          </ActionDrawerItems>
        </DrawerContainer>
      </Drawer>
    </MobileNavigationBarContainer>
  );
}

export default MobileNavBar;

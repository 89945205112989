import styled from 'styled-components';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 46px;
  align-items: center;
  flex: 1;

  @media (max-width: 805px) {
    padding: 32px;
  }

  @media (max-width: 560px) {
    padding: 24px;
  }
`;

export default SectionContainer;

// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect} from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import Header from '../../Header';
import Subheader from '../../Subheader';
import Device from '../../Device';
import AnimatedText from '../../AnimatedText';
import Iphone12 from '../../../assets/images/devices/iphone-12-compressed.png';
import AppStoreButton from '../../../assets/images/app-store-badge-alt.svg';
import GooglePlayButton from '../../../assets/images/google-play-badge.svg';
// import UpArrowImage from '../../assets/images/arrow-up.png';
import Screenshot1 from '../../../assets/images/screenshots/client-appointments.png';
import Screenshot2 from '../../../assets/images/screenshots/client-select-time-slot.png';
import Screenshot3 from '../../../assets/images/screenshots/client-reminder.png';
import Screenshot4 from '../../../assets/images/screenshots/client-modify.png';

import './TopFold.css';

const screenshots = [Screenshot1, Screenshot2, Screenshot3, Screenshot4];

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const DownloadButton = styled.img`
  height: 64px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0px 32px;
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

// const RightArrow = styled.img`
//   width: 80px;
//   position: absolute;
//   right: 270px;
//   top: 75px;
//   z-index: 999;
// `;

// const ArrowText = styled.div`
//   position: absolute;
//   right: 15px;
//   top: 180px;
//   color: #8070fd;
//   font-size: 18px;
//   font-weight: 600;
// `;

const CINDY_COLOR = '#8070fd';
const ANIMATION_PAUSE = 2500;

function DesktopFold() {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => setCounter((counter + 1) % screenshots.length), ANIMATION_PAUSE);

    return () => clearTimeout(timer);
  });

  return (
    <Container>
      <Column style={{alignItems: 'flex-end'}}>
        <div style={{width: 548, display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
          <Header>CindyApp</Header>
          <Subheader>
            <Trans>
              Book appointments <br /> at your favorite places
            </Trans>
          </Subheader>
          <br />
          <FeaturesContainer>
            <AnimatedText color={counter === 0 ? CINDY_COLOR : 'black'} size={counter === 0 ? 24 : 16}>
              <Trans>All of your services one click away</Trans>
            </AnimatedText>
            <AnimatedText color={counter === 1 ? CINDY_COLOR : 'black'} size={counter === 1 ? 24 : 16}>
              <Trans>Full schedule visiblity</Trans>
            </AnimatedText>
            <AnimatedText color={counter === 2 ? CINDY_COLOR : 'black'} size={counter === 2 ? 24 : 16}>
              <Trans>Never miss an appointment</Trans>
            </AnimatedText>
            <AnimatedText color={counter === 3 ? CINDY_COLOR : 'black'} size={counter === 3 ? 24 : 16}>
              <Trans>Easily change or cancel</Trans>
            </AnimatedText>
          </FeaturesContainer>
          <Row style={{marginTop: 24}}>
            <a href="https://apps.apple.com/bg/app/cindy/id1480948058?ls=1">
              <DownloadButton style={{marginRight: 16}} src={AppStoreButton} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.cindyapp.client">
              <DownloadButton src={GooglePlayButton} />
            </a>
          </Row>
        </div>
      </Column>
      <Column style={{paddingLeft: 80}}>
        <Device deviceImage={Iphone12} screenshots={screenshots} counter={counter} />
      </Column>
    </Container>
  );
}

export default DesktopFold;

// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import {Link as GatsbyLink} from 'gatsby';
import styled from 'styled-components';
import Header from '../../Header';
import AppStoreButton from '../../../assets/images/app-store-badge-alt.svg';
import GooglePlayButton from '../../../assets/images/google-play-badge.svg';
import {Button} from '../../styled';

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const DownloadButton = styled.img`
  width: 160px;
`;

// const Text = styled.div`
//   font-size: 18px;
//   color: gray;
//   text-align: center;
// `;

function MobileFold() {
  return (
    <Column>
      <Header mobile={true} style={{textAlign: 'center'}}>
        <Trans>Book appointments at your favorite places</Trans>
      </Header>
      <Column style={{marginTop: 30}}>
        <a href="https://apps.apple.com/bg/app/cindy/id1480948058?ls=1">
          <DownloadButton src={AppStoreButton} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.cindyapp.client">
          <DownloadButton style={{marginTop: 10}} src={GooglePlayButton} />
        </a>
        {/* <Text style={{marginTop: 50, marginBottom: 20}}>
          <Trans i18nKey="OR<br />integrate CindyApp for your business">
            OR
            <br />
            integrate CindyApp for your business
          </Trans>
        </Text> */}
        <Button as={GatsbyLink} to="#why" $border $reverse style={{width: 200, marginTop: 40}}>
          <Trans>LEARN MORE</Trans>
        </Button>
      </Column>
    </Column>
  );
}

export default MobileFold;

// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import BackgroundImage from '../../../assets/images/background-main.jpg';
import DesktopFold from './DesktopFold';
import MobileFold from './MobileFold';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 60px 50px 100px 50px;
  min-height: 400px;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;

  margin-top: 70px;

  @media (max-width: 1092px) {
    margin-top: 0px;
    padding: 84px 24px 24px 24px;
  }

  @media (max-width: 805px) {
    min-height: 500px;
  }
`;

function TopFold() {
  return (
    <>
      <Container className="index-top-fold-desktop">
        <DesktopFold />
      </Container>
      <Container className="index-top-fold-mobile">
        <MobileFold />
      </Container>
    </>
  );
}

export default TopFold;

import styled from 'styled-components';

const Header = styled.div`
  font-size: 8.8rem;
  font-weight: 600;

  @media (max-width: 805px) {
    font-size: 3.2rem;
  }
`;

export default Header;
